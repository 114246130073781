export default {
        translation: {
                'internet_connection_error': "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
                'shared.french': 'Englisch',
                'shared.english': 'Englisch',
                'login_page.title': 'Comii | Anmeldung',
                'login_page.form_title': 'Anmeldung',
                'login_page.form_action_1': 'Weiter',
                'login_page.form_country': 'Land',
                'reset_page_title': 'Passwort zurücksetzen',
                'reset_page_action': 'Code senden',
                'reset_page_subtitle': 'Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
                'reset_page_back': 'Zurück zum Login',
                'login_page_title': 'Melden Sie sich bei Ihrem Konto an',
                'new_password_title': 'Erstellen Sie Ihr neues Passwort',
                'password_reset_success': 'Passwort erfolgreich zurückgesetzt',
                'new_password': 'Neues Passwort',
                'new_password_conf': 'Passwortbestätigung',
                'label_password_is_not_same': 'Beide Passwörter müssen gleich sein',
                'validate_reset_password_title': 'Wir haben Ihnen einen Code an  gesendet',
                'validate_reset_password_action_0': 'Diese E-Mail gehört nicht Ihnen?',
                'validate_reset_password_action_1': 'E-Mail ändern',
                'new_password_action': 'Passwort erstellen',
                'reset_password_footer': 'Wenn Sie die E-Mail nicht finden können, überprüfen Sie Ihre Spam-Box.',
                'global_field_require': 'Dieses Feld ist erforderlich',
                'global_field_email': 'Bitte geben Sie eine korrekte E-Mail-Adresse ein',
                'login_page.form_phone': 'Telefonnummer',
                'login_page.password_forget': 'Passwort vergessen',
                'login_page.form_password': 'Passwort',
                'login_page.form_email': 'E-Mail-Adresse / ID',
                'category_add_title': 'Kategorie hinzufügen',
                'navigation_config_1': 'Comii-Bordbuch',
                'navigation_config_2': 'Statistiken',
                'navigation_config_3': 'Anfragen',
                'navigation_config_4': 'Dienstleistungen',
                'navigation_config_5': 'Benutzer',
                'navigation_config_6': 'Finanzen',
                'navigation_config_7': 'Logout',
                "home_user_aufgabe_status_all": "alle Aufträge",
                "home_user_aufgabe_status_gesendet": "Aufträge gesendet",
                "home_user_aufgabe_status_gestartet": "Aufträge gestartet",
                "home_user_aufgabe_status_beendet": "Auftrag Beendet",
                "home_user_aufgabe_status_akzeptiert": "Auftrag Akzeptiert",
                "home_user_aufgabe_status_bezahlt": "Aufträge bezahlt",
                'page_statistic_graph_zone_title': "Statistics",
                'page_statistic_graph_1_title': "Statistiken über registrieren Nutzer pro Monat",
                'page_statistic_graph_2_title':"Statistiken über registrierten Nutzer",
                'page_statistic_label_care_per_month': "Anzahl der Pflege pro Monat",
                'page_statistic_label_request_per_month': "Statistiken über die Anzahl des Anfragen pro Monat",
                'page_statistic_label_this_week': "diese Woche",
                'page_statistic_label_this_month': "diesen Monat",
                'page_statistic_label_last_month': "im letzten Monat",
                'page_statistic_label_this_trimester': "in diesem Quartal",
                'page_statistic_ratio_helfer_senior': "Verhältnis Helfer/Senioren",
                'page_statistic_ratio_senior_helfer': "Verhältnis Senioren/Helfer",
                'page_statistic_average_duration_assistance': "Durchschnittliche Dauer der Hilfe",
                'page_statistic_spontaneity_average': "Mittlere Spontanität",
                'page_statistic_average_coast_per_intervention': "Durchschnittliche Kosten pro Intervention",
                'page_statistic_average_service_duration': "Durchschnittliche Dauer eines Dienstes",
                'page_statistic_average_duration_of_execution_service': "Durchschnittliche Dauer der Ausführung einer Dienstleistung",
                'page_statistic_average_between_start_end_service': "Durchschnittliche Zeit zwischen der Anforderung des Dienstes und dem Beginn der Ausführung",
                'page_statistic_average_between_start_accept_service': "Durchschnittliche Zeit zwischen der Anforderung eines Dienstes und der Annahme durch einen Helfer",
                'page_statistic_average_between_create_accept_service': "Durchschnittliche Zeit zwischen der Annahme eines Dienstes und dem Beginn der Ausführung des Dienstes",
                'page_statistic_average_coat_per_service': "Durchschnittliche Kosten pro Dienst",

                'profile_user': 'Profile',
                'global_search': 'Search user',
                'password_user': 'Passwort',

                'user_page.title': 'Benutzer',
                'user_page.username': 'User name',
                'user_page.created_at': 'Registratrierung am',
                'user_page.email': 'E-mail',
                'user_page.block_user': "Benutzer blockieren",
                'user_page.unblock_user': "Benutzer freischalten",
                'user_page.show_user_details': "Benutzer anzeigen",
                'user_page.update_user': "Ändern",
                'user_page.delete_user': "Benutzer löschen",
                'user_page.delete_user_confirm_message': "Sie versuchen den Benutzer zu löschen. Diese Aktion ist unwiderruflich.",
                'user_page.delete_user_confirm': "Wollen Sie diesen Benutzer wirklich löschen?",
                'user_page.delete_success': 'Utilisateur supprimé avec succès',
                'user_page.block_user_confirm_message': "Sie versuchen den Benutzer zu sperren. Solange der Benutzer gesperrt ist, kann er die App nicht benutzen. Sie können jederzeit den Benutzer wieder entsperren.",
                'user_page.block_user_confirm': "Wollen Sie diesen Benutzer wirklich sperren?",
                'user_page.unblock_user_confirm_message': "You are about to unblock this user, he will now have access to the system",
                'user_page.unblock_user_confirm': "Are you really want to unblock this user ?",

                'user_detail_page.title': 'Userdetail',
                'user_detail_page.send_message': 'Nachricht senden',
                'user_detail_page.total_rating': 'Gesamte erhaltene Bewertungen',
                'user_detail_page.comments': 'Erhaltene Kommentare',
                'user_detail_page.tab_info': 'Informationen',
                'user_detail_page.tab_services': 'Services',
                'user_detail_page.tab_stats': 'Statistiken',
                'user_details_page.tab_alleDaten':'Alle Daten',
                'user_detail_page.firstname': 'Name',
                'user_detail_page.lastname': 'Vorname',
                'user_detail_page.civility': 'Anrede',
                'user_detail_page.gender': 'Gender',
                'user_detail_page.birthday': 'Geburtsdatum',
                'user_detail_page.phone': 'Telefonnummer',
                'user_detail_page.address': 'Anschrift',
                'user_detail_page.email': 'Email',
                'user_detail_page.password': 'Passwort',
                'user_detail_page.last_login': 'Letzte Anmeldung:',
                'user_detail_page.verified': 'Vérifié',
                'user_detail_page.joined': 'Angemeldet am',
                'user_detail_page.card_info': 'Karteninformations',
                'user_detail_page.reset_password': 'Passwort ändern',
                'user_detail_page.delete_session': 'Supprimer la session',
                'user_detail_page.helfer_name': 'Hilfer Name',

                'user_request.title': 'Auftragt',
                'user_request.status_paid': 'Auftragt bezahlt',
                'user_request.status_accepted': 'Auftragt akzeptiert',
                'user_request.status_outstanding': 'Auftragt gestartet',
                'user_request.status_pending': 'Auftragt gesendet',
                'user_request.status_pending_helfer': 'Auftragt besendet',
                'user_request.status_completed': 'Auftragt beendet',
                'user_request.status_cancelled': 'Auftragt abgebrochen',
                'user_request.status_paused': 'Auftragt pausiert',
                'user_request.status_failed': 'Auftragt fehlgeschlagen',
                'user_request.status_expired': 'Auftragt abgelaufen',
                'user_request.status_rejected_by_all': 'Auftragt abgelehnt',
                'user_request.start_at': 'Angefangen um',
                'user_request.pause_at': 'pausiert um',
                'user_request.resume_at': 'wiedergestartet um',
                'user_request.end_at': 'beendet um',
                'user_request.rating_helfer': 'Rating Helfer',
                'user_request.service_summary': 'Service summary',
                'user_request.file_attachment': 'File attachement',
                'user_request.description': 'Beschreibung',
                'user_request.posted_on': 'Erstellt am',
                'user_request.category': 'Kategorie',
                'user_request.task_name': 'Sevicename',
                'user_request.service_number': 'Servicenummer',
                'user_request.detail': 'Service details',
                'user_request.all_status': 'Alle statuts',
                'user_request.last_service_request': 'Letzte Dienstleistungsanfrage',
                'request_page.start_date': 'Von',
                'request_page.end_date': 'Bis',
                'request_page.file_type': 'Fileformat',

                'user_comment.title': 'Erhaltene Kommentare',
                'user_comment.total_comments': 'Ingesamt :count Kommentaren',
                'user_comment.load_more': 'Afficher plus',
                'today': 'Heute',
                'yesterday': 'Gestern',

                'request_page.title': 'Anfragen',
                'request_page.service_id': 'Dienst-Id',
                'request_page.service_name': 'Servicename',
                'request_page.category': 'Kategorie',
                'request_page.created_by': 'Angefordert von',
                'request_page.created_at': 'Angefordert am',
                'request_page.status': 'Status',
                'user_request.all_services': 'Alle services',

                'global_label.latest_update': "Lastest update",
                'global_label.delete': "Supprimer",
                'global_label.add': "Ajouter",
                'global_label.no': "Nein",
                'global_label.yes': "Ja!",
                'global_label.view_detail': 'Details anschauen',
                'global_label.status_paid': 'bezahlt',
                'global_label.status_accepted': 'akzeptiert',
                'global_label.status_outstanding': 'gestartet',
                'global_label.status_pending': 'gesendet',
                'global_label.status_pending_helfer': 'besendet',
                'global_label.status_completed': 'beendet',
                'global_label.status_cancelled': 'annulliert',
                'global_label.status_paused': 'pausiert',
                'global_label.status_rejected_by_all': 'abgelehnt',
                'global_label.status_failed': 'fehlgeschlagen',
                'global_label.status_expired': 'abgelaufen',
                'at': 'um',
                "global_label_on_average": "im durchschnitt",
                'global_label.export': "Exportieren",

                "home_user_aufgabe_status_PENDING_HELFER": "Helfersuche",
                "home_user_aufgabe_status_PENDING": "Aufträge gesendet",
                "home_user_aufgabe_status_COMPLETED": "Auftrag Beendet",
                "home_user_aufgabe_status_ACCEPTED": "Auftrag Akzeptiert",
                "home_user_aufgabe_status_OUTSTANDING": "Aufträge gestartet",
                "home_user_aufgabe_status_PAID": "Auftrag bezahlt",
                "global_today_date": "Heute",
                "global_yesterday_date": "Gestern",
                "home_user_stats_year": "Dieses Jahr",
                "home_user_total_senior": "Senioren insgesamt",
                "home_user_total_helfer": "Helfer insgesamt",
                "home_user_total_request": "Anfragen gesendet",
                "home_user_total_apart": "Erledigte Anfragen",
                "home_user_trend_senior": "{{trend}} neue Benutzer ",
                "home_user_trend_request": "{{trend}} neuen Anfrage",
                "home_user_trend_appart": "{{trend}} neuen Anfrage",
                "home_user_trend": "Tendanz",
                "home_user_customer_list": "Benutzerliste",
                "home_user_customer_filter_all": "Alle Benutzer",
                "home_user_customer_filter_seniors": "Senioren",
                "home_user_customer_filter_helfer": "Helfer",
                "home_user_customer_name": "Kundenname",
                "home_user_customer_created_at": "Registriert am",
                "home_user_customer_phone": "Telefon",
                "home_user_customer_type": "Kontotyp",
                "home_user_customer_status": "Status",
                "home_user_customer_actions": "Aktionen",
                "home_user_customer_online": "Online",
                "home_user_customer_offline": "Offline",
                "home_user_aufgabe": "Aufgabe",
                "home_user_aufgabe_id": "ID",
                "home_user_aufgabe_name": "Dienstname",
                "home_user_aufgabe_von": "Erstellt von",
                "home_user_aufgabe_date": "Datum",
                "home_user_aufgabe_status": "Status",
                "home_user_aufgabe_status_service": "Aufträge abgeschlossen",
                "home_user_stats_month": "Diesen Monat",
                "home_user_stats_day": "Heute",
                "global_rows_per_page": "Pro Seite",
                "filter_user_stats_per_month": "Pro Monat",
                "filter_user_stats_per_year": "Nach Jahren",
                "home_user_stats_online": "{{count}} online",
                "home_user_stats_progress": "{{count}} in Bearbeitung",
                "home_user_task_status_all": "Alle Status",
                "order_analytics": "Bestellanalyse",
                "messages_textfield": "Ihre Nachricht",
                "send_messages_textfield": "Senden",
                "global_label_notifications": "Benachrichtigungen",
                "label_unread": "Ungelesen",
                'label_all_notification': "Alle",
                'btn_label_edit_profile': "Profil ändern",
                'edit_user_info':'Benutzer ändern',
                'global_label_save': "Speichern",
                "label_civility_FRAU": "Frau",
                "label_civility_ANDERE": "Andere",
                "label_civility_HERR": "Herr",
                "label_gender_MÄNNLICH": "Männlich",
                "label_gender_WEIBLICH": "Weiblich",
                "label_gender_ANDERE": "Andere",
                "label_manage_password": "Passwort verwalten",
                "label_manage_password_description": "Wenn Sie Ihr Passwort ändern, müssen Sie sich erneut anmelden, um Ihre Identität zu überprüfen",
                "btn_label_edit_password": "Passwort ändern",
                "label_old_password": "Aktuelles Passwort",

                "service_page.services": "Services",
                "service_page.category": "Kategorie",
                "service_page.add_service": "Service hinzufügen",
                "service_page.add_service_title": "Neue Service hinzufügen",
                "service_page.edit_service": "Dienst bearbeiten",
                "service_page.delete_service": "Service löschen",
                "service_page.delete_service_title": "Service entfernen",
                'service_page.delete_service_confirm_message': "Sie versuchen gerade einen Service zu löschen, Diese Aktion ist unwiederrüflich",
                'service_page.delete_service_confirm': "Wollen Sie wirklich diesen Service löschen ?",
                'service_page.delete_success': 'Service deleted successfully',
                'service_page.select_service_category': '-- Servicekategorie hier auswählen',
                'service_page.update_service': 'Update service',
                'service_page.create_new_service': 'Create a new service',
                'service_page.service_title_placeholder': 'Servicename bitte hier eingeben',

                "update_avatar_success_message": "Profilbild erfolgreich aktualisiert",
                "update_infos_success_message": "Informationen wurden erfolgreich aktualisiert",
                "btn_label_add_user": "Benutzer",
                "add_user_modal_title": "Einen neuen Nutzer erstellen",
                "label_admin": "Administrator",
                "label_location": "Ort",
                "label_postal_code": "Plz",
                "label_street": "Straße",
                "label_street_nr": "Nr.",
                "add_user_success_message": "Benutzer erfolgreich hinzugefügt",
                'user_detail_page.service_created': "Erstellte Dienste",
                'user_detail_page.request_sent': "Requests sent",
                'user_detail_page.treated_service': "Behandelte Dienstleistungen",
                "user_detail_page.trend_service_request": "+{{trend}} new request",
                "user_detail_page.trend": "+{{trend}} new",
                "user_detail_page.request_graph_title": "Statistiken",
                "user_detail_page.request_graph_description": "Statistiken über die Anzahl des Anfragen pro Monat",
                "user_detail_page.request_graph_per_month": "Anzahl der Pflege pro Monat",
                "label_password_is_not_strong": "Passwort muss mindestens 8 Zeichen, Großbuchstaben, Zahlen und Sonderzeichen enthalten (!@#$%^&*)",
                'user_detail_page.request_received': "Anfrage erhalten",
                'user_detail_page.request_rejected': "Anfrage abgelehnt",
                'user_detail_page.request_completed': "Anfrage abgeschlossen",
                "user_detail_page.in_progress": "{{count}} in progress",
                'user_detail_page.remove_user': 'Benutzer entfernen',
                'user_detail_page.user_status': 'User status',
                'user_detail_page.user_status_activated': 'Ermöglicht',
                'user_detail_page.user_status_blocked': 'Blockiert',
                'user_detail_page.user_deactivate': 'Deaktivieren',
                'user_detail_page.user_activate': 'Aktivieren',
                "global_label.retry": "Retry",
                "service_label_match_maker": "Match maker",
                'user_detail_page.request_accepted': "Anfrage akzeptiert",
                "label_show_helfer": "Den Helfer anzeigen",
                "label_check_match": "Überprüfe die Übereinstimmung",
                "user_detail_page.card_holder": "Card holder",
                "user_detail_page.card_expires": "Expires",
                "user_detail_page.used_card": "Für den folgenden Se",
                "user_detail_page.last_cards": "Andere gespeicherte Bankkarte",
                "user_detail_page.cards_count": "{{count}} Bankkarte sind von dem Benutzer gespeichert worden ",
                "global_label_cancel": "Cancel",
                "global_label.accepted_at": "Akzeptiert um",
                "user_detail_page.request": "Anfrage",
                "user_detail_page.request_confirmation": "Bestätigung",
                "user_detail_page.request_start": "Begin",
                "DIESES_YEAR": "Dieses (Jahr)",
                "LETZTE_MONAT": "Letzter Monat",
                "LETZTE_WOCHE": "Letzte Woche",
                "LETZTE_14_TAGE": "Letzten 14-Tage",
                "service_request_by":"Service beantragt von :",
                "service_accepted_by":"Service akzeptiert von  :",
                "translate_key_digram_title":"Auftragsanalytics",
                "translate_key_service_accepted_not_paid":"Auftrag",
                "translate_key_service_accepted_and_paid":"Auftrag bezahlt"
        }
};