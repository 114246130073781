export default {
    translation: {
        'internet_connection_error': "Une erreur est survenue, veuillez réessayer plus tard.",
        'shared.french': 'Français',
        'shared.english': 'English',
        'login_page.title': 'Comii | Login',
        'login_page.form_title': 'Login',
        'login_page.form_action_1': 'Continue',
        'login_page.form_country': 'Country',
        'reset_page_title': 'Reset Password',
        'reset_page_action': 'Send code',
        'reset_page_subtitle': 'Please enter the email address associated with your account to reset your password.',
        'reset_page_back': 'Return to login',
        'login_page_title': 'Log in to your account',
        'new_password_title': 'Create your new password',
        'password_reset_success': 'Password reset successfully',
        'new_password': 'New password',
        'new_password_conf': 'Password confirmation',
        'label_password_is_not_same': 'Both passwords must be the same',
        'validate_reset_password_title': 'We sent you a code at ',
        'validate_reset_password_action_0': 'This email is not yours?',
        'validate_reset_password_action_1': 'Change email',
        'new_password_action': 'Create password',
        'reset_password_footer': 'If you can\'t find the email, check your spam box.',
        'global_field_require': 'This field is required',
        'global_field_email': 'Please enter a correct email address',
        'login_page.form_phone': 'Phone Number',
        'login_page.password_forget': 'Forgot password',
        'login_page.form_password': 'Password',
        'user_detail_page.treated_service': "Treated Services",
        'login_page.form_email': 'Email address / ID',
        'user_detail_page.service_created': "Services created",
        'category_add_title': 'Add Category',
        "global_today_date": "Today",
        "global_yesterday_date": "Yesterday",
        'navigation_config_1': 'Comii Log',
        "messages_textfield": "Your message...",
        "send_messages_textfield": "Send",
        'navigation_config_2': 'Statistics',
        'navigation_config_3': 'Queries',
        'navigation_config_4': 'Services',
        'navigation_config_5': 'User',
        'navigation_config_6': 'Finance',
        'navigation_config_7': 'Logout',
        'profile_user': 'Profile',
        'global_search': 'Search',
        'password_user': 'Mot de passe',
        'page_statistic_graph_zone_title': "Statistiken",
        'page_statistic_graph_1_title': "Statistiques sur les utilisateurs enregistrés par mois",
        'page_statistic_label_care_per_month': "Statistics on registered users per month",
        'page_statistic_label_request_per_month': "Statistics on the number of requests per month",
        'page_statistic_label_this_week': "this week",
        'page_statistic_label_this_month': "this month",
        'page_statistic_label_last_month': "last month",
        'page_statistic_label_this_trimester': "this trimester",
        'page_statistic_ratio_helfer_senior': "Relation helper/senior",
        'page_statistic_ratio_senior_helfer': "Relation senior/helfer",
        'page_statistic_average_duration_assistance': "Average duration of assistance",
        'page_statistic_spontaneity_average': "Spontaneity Average",
        'page_statistic_average_coast_per_intervention': "Average cost per intervention",
        'page_statistic_average_service_duration': "Average duration of a service ",
        'page_statistic_average_duration_of_execution_service': "Average duration of the execution of a service",
        'page_statistic_average_between_start_end_service': "Average time between the request for the service and the start of execution",
        'page_statistic_average_between_start_accept_service': "Average time between the request for a service and the acceptance by a helper",
        'page_statistic_average_between_create_accept_service': "Average time between the acceptance of a service and the start of the execution of the service",
        'page_statistic_average_coat_per_service': "Average cost per service",

        'user_page.title': 'User list',
        'user_page.username': 'Username',
        'user_page.created_at': 'Created at',
        'user_page.email': 'E-mail',
        'user_page.block_user': "Block user",
        'user_page.unblock_user': "Unblock user",
        'user_page.show_user_details': "User details",
        'user_page.update_user': "Edit",
        'user_page.delete_user': "Delete user",
        'user_page.delete_user_confirm_message': "You are about to delete this user, it will no longer be visible in the system",
        'user_page.delete_user_confirm': "Are you really want to delete this user ?",
        'user_page.delete_success': 'User successfully deleted',
        'user_page.block_user_confirm_message': "You are about to block this user, he will no longer have access to the system",
        'user_page.block_user_confirm': "Are you really want to block this user ?",
        'user_page.unblock_user_confirm_message': "You are about to unblock this user, he will now have access to the system",
        'user_page.unblock_user_confirm': "Are you really want to unblock this user ?",

        'user_detail_page.title': 'User detail',
        'user_detail_page.send_message': 'Send a message',
        'user_detail_page.total_rating': 'Total Rating',
        'user_detail_page.comments': 'Comments',
        'user_detail_page.tab_info': 'Informations',
        'user_detail_page.tab_services': 'Services',
        'user_detail_page.tab_stats': 'Statistics',
        'user_details_page.tab_alleDaten':'All Data',
        'user_detail_page.firstname': 'Firstname',
        'user_detail_page.lastname': 'Lastname',
        'user_detail_page.civility': 'Civility',
        'user_detail_page.gender': 'Gender',
        'user_detail_page.birthday': 'Day of birth',
        'user_detail_page.phone': 'Phone number',
        'user_detail_page.address': 'Address',
        'user_detail_page.email': 'Email',
        'user_detail_page.password': 'Password',
        'user_detail_page.last_login': 'Dernière connexion',
        'user_detail_page.verified': 'Verified',
        'user_detail_page.joined': 'Registered on',
        'user_detail_page.card_info': 'Card informations',
        'user_detail_page.reset_password': 'Update password',
        'user_detail_page.delete_session': 'Delete my session',

        'user_request.title': 'Service request',
        'user_request.status_paid': 'Service paid',
        'user_request.status_outstanding': 'Service accepted',
        'user_request.status_pending': 'Service pending',
        'user_request.status_pending_helfer': 'Service sent',
        'user_request.status_completed': 'Service completed',
        'user_request.status_cancelled': 'Service cancelled',
        'user_request.status_paused': 'Service paused',
        'user_request.status_failed': 'Service failed',
        'user_request.status_expired': 'Service expired',
        'user_request.status_rejected_by_all': 'Service rejected',
        'user_request.start_at': 'Start at',
        'user_request.pause_at': 'Pause at',
        'user_request.resume_at': 'Resume at',
        'user_request.end_at': 'End at',
        'user_request.rating_helfer': 'Rating helfer',
        'user_request.service_summary': 'Service summary',
        'user_request.file_attachment': 'File attachement',
        'user_request.description': 'Description',
        'user_request.posted_on': 'Posted on',
        'user_request.category': 'Category',
        'user_request.task_name': 'Task name',
        'user_request.service_number': 'Service number',
        'user_request.detail': 'Request details',
        'user_request.all_status': 'All status',
        'user_request.last_service_request': 'Last service request',

        'user_comment.title': 'Comments',
        'user_comment.total_comments': 'A total of :count comments',
        'user_comment.load_more': 'Show more',
        'today': 'Heute',
        'yesterday': 'Gestern',

        'request_page.title': 'Servie requests',
        'request_page.service_id': 'Service id',
        'request_page.service_name': 'Service name',
        'request_page.category': 'Category',
        'request_page.created_by': 'Created by',
        'request_page.created_at': 'Created at',
        'request_page.status': 'Status',
        'user_request.all_services': 'All services',
        'request_page.start_date': 'De',
        'request_page.end_date': 'A',
        'request_page.file_type': 'Format',

        'global_label.export': "Export",
        'global_label.latest_update': "Lastest update",
        'global_label.delete': "Delete",
        'global_label.add': "Add",
        'global_label.no': "No",
        'global_label.yes': "Yes!",
        'global_label.view_detail': 'View details',
        'global_label.status_paid': 'paid',
        'global_label.status_outstanding': 'accepted',
        'global_label.status_pending': 'pending',
        'global_label.status_pending_helfer': 'sent',
        'global_label.status_completed': 'completed',
        'global_label.status_cancelled': 'cancelled',
        'global_label.status_paused': 'paused',
        'global_label.status_rejected_by_all': 'rejected',
        "global_label_on_average": "on average",
        "home_user_aufgabe_status_all": "All statuses",
        "home_user_aufgabe_status_gesendet": "Task sent",
        "home_user_aufgabe_status_gestartet": "Task started",
        "home_user_aufgabe_status_beendet": "Task Completed",
        "home_user_aufgabe_status_akzeptiert": "Task Accepted",
        "home_user_aufgabe_status_bezahlt": "Job paid",
        "home_user_aufgabe_status_PENDING_HELFER": "Task sent",
        "home_user_aufgabe_status_PENDING": "Task started",
        "home_user_aufgabe_status_COMPLETED": "Task Completed",
        "home_user_aufgabe_status_OUTSTANDING": "Task Accepted",
        "home_user_aufgabe_status_PAID": "Job paid",
        "home_user_stats_year": "This year",
        "home_user_total_senior": "Senior total",
        "home_user_total_helfer": "Total helper",
        "home_user_total_request": "Request sent",
        "home_user_total_apart": "Resolved request",
        "home_user_trend_senior": "+{{trend}} new registrants",
        "home_user_trend_request": "+{{trend}} new request",
        "home_user_trend_appart": "+{{trend}} new",
        "home_user_trend": "Trend",
        "home_user_customer_list": "User List",
        "home_user_customer_filter_all": "All users",
        "home_user_customer_filter_seniors": "Seniors",
        "home_user_customer_filter_helfer": "Helpers",
        "home_user_customer_name": "Customer name",
        "home_user_customer_created_at": "Registered on",
        "home_user_customer_phone": "Phone",
        "home_user_customer_type": "Account type",
        "home_user_customer_status": "Status",
        "home_user_customer_actions": "Actions",
        "home_user_customer_online": "Online",
        "home_user_customer_offline": "Offline",
        "home_user_aufgabe": "Task",
        "home_user_aufgabe_id": "ID",
        "home_user_aufgabe_name": "Service name",
        "home_user_aufgabe_von": "Created by",
        "home_user_aufgabe_date": "Date",
        "home_user_aufgabe_status": "Status",
        "home_user_stats_month": "This month",
        "home_user_stats_day": "Today",
        "global_rows_per_page": "Per page",
        "filter_user_stats_per_month": "Per month",
        "filter_user_stats_per_year": "By years",
        "home_user_stats_online": "{{count}} online",
        "home_user_stats_progress": "{{count}} in progress",
        "home_user_task_status_all": "All statuses",
        "order_analytics": "Order Analysis",
        'global_label.status_failed': 'failed',
        'global_label.status_expired': 'expired',
        'at': 'at',
        "global_label_notifications": "Notifications",
        "label_unread": "Unreads",
        'label_all_notification': "All",
        'btn_label_edit_profile': "Edit profil",
        'global_label_save': "Save",
        "label_civility_HERR": "Mr.",
        "label_civility_FRAU": "Mrs.",
        "label_civility_ANDERE": "Other",
        "label_gender_MÄNNLICH": "MALE",
        "label_gender_WEIBLICH": "FEMALE",
        "label_gender_ANDERE": "Other",
        'label_manage_password': "Manage password",
        'label_manage_password_description': "If you change your password, you will need to log in again to verify your identity",
        "btn_label_edit_password": "Edit password",
        "label_old_password": "Actual password",
        "update_avatar_success_message": "Profile picture updated successfully",
        "update_infos_success_message": "Infomations updated successfully",
        "btn_label_add_user": "User",
        "add_user_modal_title": "Create a new user",
        "label_admin": "Administrator",
        "label_location": "Town",
        "label_postal_code": "Postal code",
        "label_street": "Street",
        "label_street_nr": "N°",
        "add_user_success_message": "User successfully added",

        "service_page.services": "Services",
        "service_page.category": "Categories",
        "service_page.add_service": "Add service",
        "service_page.edit_service": "Update service",
        "service_page.delete_service": "Delete service",
        'service_page.delete_service_confirm_message': "You are about to delete this service, it will no longer be visible to your users",
        'service_page.delete_service_confirm': "Are you really want to delete this service ?",
        'service_page.delete_success': 'Service deleted successfully',
        'service_page.select_service_category': '-- Select the service category',
        'service_page.update_service': 'Update service',
        'service_page.create_new_service': 'Create a new service',
        'service_page.service_title_placeholder': 'Please, name your service',
        "label_password_is_not_strong": "The password must contain at least 8 characters, capital letters, numbers and special characters (!@#$%^&*)",
        "service_label_match_maker": "Match maker",
        "label_show_helfer": "Show the helfer",
        "label_check_match": "Check the match",
    }
};